import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '../../Components/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '../../Components/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Servicos from '../../Components/Servicos/Block01'
import { Container } from 'theme-ui'

import theme from './_theme'
import styles from './_styles'

const ServicosPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  // console.log(content['servicos']);
  return (
    <Layout theme={theme} {...props}>
      <Seo title='Serviços' />
      <ModalWithTabs content={content['faleconosco']} />
      <ModalSimple content={content['freebsd']} />
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Servicos content={content['servicos']} />
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageServicosBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/servicos", "site/servicos/modal" , "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default ServicosPage
